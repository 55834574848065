import React from "react";
import { useState } from "react";

import Cta from '../CTA/Cta'
import { Underlines } from "../Underlines/Underlines";
import practices from "./data"
import "./practice.less"
import {UnderlineSm} from '../Underlines/Underlines'

export default ()=>{
    // const [active, setActive] = useState(initialState)

    const [currentTab, setCurrentTab] = useState(1)

    const changeTab = (id)=>{
        setCurrentTab(id);
    }


    return(
        <section className="practices" id="practice">
            <article className="container">
                <Underlines/>
                <h2>Practice Areas</h2>

                <article className="btn-container">
                    {
                        practices.map(({id, title})=>{
                            return(
                                <button key={id} onClick={()=>{changeTab(id)}} className={currentTab===id ? "active": "inactive"}>{title}</button>
                            )
                        })
                    }
                </article>
                {
                    practices.map(({id, img, title, description})=>{
                        return (
                            <article key= {id} className={`practice-content ${id===currentTab? "active" : "inactive"}`}>
                                <img src={img} alt={title} /> 
                                <div className="content">
                                    <h3>{title}</h3> 
                                    <UnderlineSm/>
                                    {description.split(':').map((str, idx)=>{
                                            return(
                                                <p>{str}</p>
                                            )
                                    })}
                                    <Cta freeCase={false} caseEval={false} simpleText={true}/>
                                    <h3 className="practice-notice">
                                        Our New York City personal injury lawyers & attorneys help victims of personal injuries and accidents in Queens, Brooklyn, Manhattan, Staten Island, the Bronx, and areas within Long Island such as Nassau County and Suffolk County.
                                    </h3>
                                </div>
                            </article>
                        )
                    })
                }
            </article>
        </section>
    )
}