import React, { Component } from "react";
import { useEffect, useState } from "react";
import { Underlines } from "../Underlines/Underlines";
import Slider from "react-slick";
import CertificateOne from "../../../images/certficates/ABA.png";
import CertificateTwo from "../../../images/certficates/Expertise.png";
import CertificateThree from "../../../images/certficates/Lawyer of distinction.png";
import CertificateFour from "../../../images/certficates/NYSBA.png";
import CertificateFive from "../../../images/certficates/TopAttorneys.png";
import CertificateSix from "../../../images/certficates/AmericanInstituteOfLegalAdvocates.png";
import CertificateSeven from "../../../images/certficates/BestLawFirmInAmerica.png";
import CertificateEight from "../../../images/certficates/BestLawfirm2023.png";
import CertificateNine from "../../../images/certficates/LitigatorOfTheYear.png";

import "./awards.less";

export default class MultipleItems extends Component {
  render() {
    const images = [
      CertificateOne,
      CertificateTwo,
      CertificateThree,
      CertificateFour,
      CertificateFive,
      CertificateSix,
      CertificateSeven,
      CertificateEight,
      CertificateNine,
    ];
    return (
      <section className="awards" id="awards">
        <article className="awards-wrapper sm">
          <Slider
            swipeToSlide={true}
            slidesToShow={3}
            focusOnSelect={true}
            autoplay={true}
            autoplaySpeed={6000}
            lazyLoad={true}
            cssEase={"ease-in-out"}
          >
            {images?.map((image, idx) => {
              return (
                <img src={image} alt="" className="single-award" key={idx} />
              );
            })}
          </Slider>
        </article>
        <article className="awards-wrapper md">
          <Slider
            swipeToSlide={true}
            slidesToShow={3}
            focusOnSelect={true}
            autoplay={true}
            autoplaySpeed={6000}
            lazyLoad={true}
            cssEase={"ease-in-out"}
          >
            {images?.map((image, idx) => {
              return (
                <img src={image} alt="" className="single-award" key={idx} />
              );
            })}
          </Slider>
        </article>
        <article className="awards-wrapper xl">
          {images?.map((image, idx) => {
            return (
              <div key={idx}>
                <img src={image} alt="" className="single-award" />
              </div>
            );
          })}
        </article>
      </section>
    );
  }
}
