import React, {useState} from "react";
import './cta.less'
import Consult from '../Hero/Consult'
import {FaPhoneAlt} from "react-icons/fa"
import { IoIosClose } from "react-icons/io"

export default ({caseEval, freeCase, simpleText})=>{
    const [modal, setModal] = useState(false);

    const openModal = ()=>{
        setModal(true);
    }
    const closeModal = ()=>{
        setModal(false);
    }

    return(
        <section className="cta">
            <div className="contact-container">
                <a href="tel:718-276-2800"><span><FaPhoneAlt /></span> 718-276-2800</a>
                <button onClick={openModal}>{caseEval? "Free Case Evaluation" : ""}{freeCase? "Get FREE Case Evaluation" : ""}{simpleText? "Free Consultation" : ""}</button>
            </div>

            <div className={`modal ${ modal? "" : "hide"}`}>
                <div className="modal-container">
                    <Consult btnID={1}/>
                    <button onClick={closeModal} className="remove-btn"><IoIosClose/></button>
                </div>
            </div>
        </section>

    )
}