import React from "react"
import { FaPhoneAlt } from "react-icons/fa"
import { Underlines } from "../Underlines/Underlines"
import Cta from "../CTA/Cta"
import AlexNatalia from "../../../images/alex&natalia.jpg"
import {GiCheckMark} from 'react-icons/gi'
import "./injury.less"

export default () => {
    return (
        <section className="injuries" id="team">
            <article className="container">
                <div className="u-hide">
                    <Underlines />
                </div>
                <div className="underline-injury">
                    <div className="underline-one"></div>
                    <div className="underline-two"></div>
                    <div className="underline-one"></div>
                </div>

                <div className="injury-grid">
                    <article className="injury-lawyers">
                        <img src={AlexNatalia} alt="Alex & Natalia" />
                        <p className="name-tag alex">Alex Yadgarov</p>
                        <p className="name-tag natalia">Natalia Derin</p>
                        {/* <Cta simpleText={true} freeCase={false} caseEval={false}/> */}
                    </article>

                    <article className="injury-content">
                        <h1>New York City Injury Lawyers</h1>
                        <h3>We Put You First</h3>
                        <p>
                            Since 2009, Alex Yadgarov & Associates have worked on Car Accident Injury cases for clients in Queens, Bronx, Brooklyn & Manhattan in the New York City area, from catastrophic car accidents to wrongful death claims.
                            <br />
                            <br />
                            If you find yourself injured at the expense of a negligent party, you may be entitled to financial compensation.
                            <br /> 
                            <br /> 
                            We fight for you till you get compensated. Our law firm was awarded the “Best car accident lawyers” in Queens, New York. 
                        </p>

                        {/* <div className="stats-container">
                            <div className="stat">
                                <h3>1 Billion<span>+</span></h3>
                                <p>Won in Cases</p>
                            </div>
                            <div className="stat">
                                <h3>2500<span>+</span></h3>
                                <p>Cases Handled</p>
                            </div>
                            <div className="stat">
                                <h3>12<span>+</span> Years</h3>
                                <p>Of Expereience</p>
                            </div>
                        </div> */}
                    </article>
                </div>

                <div className="injury-point-container">
                    <div className="point-wrapper">
                        <ul>
                            <li><span><GiCheckMark/></span> Millions Won for Our Injury Clients</li>
                            <li><span><GiCheckMark/></span> FREE Until You Win</li>
                            <li><span><GiCheckMark/></span> 150+ Happy 5-Star Reviews</li>
                            <li><span><GiCheckMark/></span> Award-Winning Legal Team</li>
                            <li><span><GiCheckMark/></span> 2,500+ Injury Cases Won</li>
                        </ul>
                        <a href="tel:718-276-2800" className='btn'>
                            Get Your FREE Consultation Now 
                            <span>
                                718-276-2800
                            </span> 
                        </a>
                    </div>
                </div>        
            </article>

        </section>
    )
}
