import car from "../../../images/car-accidents.jpg";
import sf from "../../../images/slip-fall.jpg";
import worker from "../../../images/worker-related.jpg";
import product from "../../../images/product-liability.jpg";
import bus from "../../../images/bus-accidents.jpg";
import train from "../../../images/train-accidents.jpg";
import scaffold from "../../../images/scaffold-acc.jpg";
import medicaid from "../../../images/medicaid.jpg";
import ceiling from "../../../images/ceiling-collapse.jpg";
import truck from "../../../images/truck-accident.jpg";

export default [
  {
    id: 1,
    title: "Car Accidents",
    img: car,
    description:
      "Our attorneys at Alex Yadgarov & Associates know how painful auto accident injuries can be. After you’ve gone through the stress of getting treated for your symptoms, you may also be wondering how to recover any lost items, or whether you may be entitled to compensation. : Our car accident lawyers in Rosedale proudly serve Queens and the surrounding boroughs. We are committed to relieving our clients’ stress through handling their cases and accommodating to their personal needs.",
  },
  {
    id: 2,
    title: "Slips & Falls",
    img: sf,
    description:
      "If you’ve been in a slip and fall accident, you’re not alone. More than one million people who’ve slipped and fallen have required emergency medical care for their injuries every year in the United States – more than 2,000 people each day. Treatment for accidents can add up, whether it’s a medical bill or missed days of work. : Our caring attorneys at Alex Yadgarov & Associates help our clients in Rosedale, as well as Queens and the other New York boroughs, to secure the compensation they’re qualified for so they can move on quickly to recovery.",
  },
  {
    id: 3,
    title: "Worker Related Injuries",
    img: worker,
    description:
      "Navigating a workplace injury can be confusing. After you’ve treated your injuries, you may be wondering how to receive compensation, and who the responsible party is. : In New York, injuries sustained while on the job are covered by workers' compensation, but a third party may be held liable. Alex Yadgarov & Associates can review your case and help you seek compensation.",
  },
  // {
  //     id: 4,
  //     title: "Product Liability",
  //     img: product ,
  //     description: "As a consumer, you expect your products to work, and rightly so. Unfortunately, many products can contain design or manufacturing flaws. Every year, 34 million people are injured or killed by defective products. : If you or someone you love has been injured by a dangerous or defective product, you may be entitled to financial compensation. Our product liability attorneys in Queens at Alex Yadgarov & Associates can help you work through the hardships you’re facing."
  // },
  {
    id: 5,
    title: "Bus Accidents",
    img: bus,
    description:
      "Bus injuries are serious and deserve the special kind of attention our New York bus accident attorneys can provide. Thanks to the bustle and congestion of New York City, bus accidents are becoming more common. : Whether you’ve been struck by a bus or are injured as a rider, you need the right team representing your case. Alex Yadgarov & Associates can help bus crash victims achieve compensation for their suffering.",
  },
  {
    id: 6,
    title: "Train Accidents",
    img: train,
    description:
      "Have you been injured in a train accident? Chances are, whether they're physical or mental, your injuries are serious, and the legal process to achieve compensation can be stressful. : Our train accident lawyers in Queens and Rosedale know the ins and outs of personal injury lawsuits related to these kinds of accidents and are committed to our clients, so you can rest knowing you’re being fairly represented.",
  },
  {
    id: 7,
    title: "Scaffolding Accidents",
    img: scaffold,
    description:
      "Getting injured in a scaffolding accident can be a traumatic and painful experience, whether you’re a construction worker or a nearby pedestrian. Of the 500,000 construction accidents that occur each year, one out of every five accidents involves scaffolding. : Even if you’re a construction worker injured on the job, workers’ compensation may not be enough to cover the cost of your injuries. Our Rosedale scaffold accident attorneys can pursue your case and help you gain back what you’ve lost.",
  },
  {
    id: 8,
    title: "Truck Accidents",
    img: truck,
    description:
      "Truck accidents are inevitable in a congested metropolis like New York City. Our law firm is well equipped with the knowledge and know-how to ensure you get the compensation & legal representation you deserve. : We’re dedicated to helping you and your family fight for what is rightfully yours with our step-by-step approach to your litigation. We make sure to keep you up-to-date with all critical information regarding your case and pride ourselves in maintaining 100% transparency with you at all times.",
  },
  {
    id: 9,
    title: "Ceiling Collapse Accident",
    img: ceiling,
    description:
      "Ceiling collapse accidents occur more likely than people assume and can be devastating to you and your loved ones. Whether it’s negligence from a landlord or other issues, we make sure you know your rights. : Don’t let the chance slip by to get the recovery you’re lawfully entitled to. Our legal team will make sure to guide you through every step until you get maximal justice for your pain & suffering.",
  },
  // {
  //     id: 10,
  //     title: "Medicaid Fraud",
  //     img: medicaid ,
  //     description: "Being accused of fraud can feel overwhelming, and you might not be aware of the kinds of protections you have as the accused. Medicaid fraud involves the deliberate altering of the truth to gain benefits. If you find yourself involved in a Medicaid fraud case in Queens or the surrounding boroughs, you’ll need to know your rights as a U.S. citizen, especially since there are implications at both the state and federal level. Our personal injury attorneys are well-versed in Medicaid fraud and can immediately advocate for you so that you feel supported."
  // },
];
